import { createApp } from 'vue'
import App from './App.vue'

// 全局引入ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入router
import router from './router/index'

// 导入axios实例
import service from '@/request/index';
// import constant from './const/const.js'

const  app = createApp(App);

// 全局挂载axios
// app.config.globalProperties.$axios = service;

app.provide('$axios', service)
app.use(ElementPlus).use(router).mount('#app')
