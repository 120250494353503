const routes = [
    {
        name: 'DemoWord',
        path: '/demo/',
        component: () => import('@/view/DemoWorld'),
        meta:{
            title: '测试页',
            keepAlive: false
          }
    }, {
        name: 'helloworld',
        path: '/about/',
        component: () => import('@/components/HelloWorld'),
    }, {
        name: 'privacy',
        path: '/privacy/',
        component: () => import('@/view/PrivacyView')
    }, {
        name: 'home',
        path: '/',
        title: '首页',
        component: () => import('@/view/HomeView'),
        meta:{
            title: '首页',
            keepAlive: false
          }
    },
    {
        name: 'login',
        path: '/login/',
        component: () => import('@/view/LoginView'),
        meta: {
            title: "用户登录"
        }
    },
    {
        name: 'register',
        path: '/register/',
        component: () => import('@/view/RegisterView'),
        meta: {
            title: "新用户注册"
        }
    }

];

export default routes