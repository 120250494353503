import axios from 'axios'
import { ElNotification } from 'element-plus'


function getToken() {
	return localStorage.getItem('access_token');
}

// 创建一个 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL, // 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: true, // 异步请求携带cookie
	headers: {
        'Authorization': 'Bearer ' + getToken(),
        "Content-Type": "application/json;charset=UTF-8"
    }
});

// 异常拦截处理器
const errorHandler = (error) => {
	// console.log(error.response);

	if (error.message === 'Network Error') {
		ElNotification.error('系统异常')
	}
	if (error.response) {
        const data = error.response.data;
		// 从本地存储加载token
		const access_token = localStorage.getItem('access_token');

		if (error.response.status === 403) {
			ElNotification.error(data.msg)
			// eslint-disable-next-line no-mixed-spaces-and-tabs
    	}
		// 判断http状态是否是401
		if (error.response.status === 401) {
			// 调接口，刷新token
			error.config.headers.Authorization = access_token;
		}

	}
	return Promise.reject(error)
}

// 添加请求拦截器
service.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		// 从本地存储加载token
		const access_token = localStorage.getItem('access_token');
		if(access_token) {
			config.headers.Authorization = `Bearer ${access_token}`;
		}else{
			//调整到登录界面
		}
		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	function (response) {

		// console.log(response)
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		// dataAxios 是 axios 返回数据中的 data
		const data = response.data
		// 这个状态码是和后端约定的
		// const code = dataAxios.reset
		return data
	}, errorHandler
)
export default service
