import {createRouter, createWebHistory} from 'vue-router'
import routes from './routes'
import { ElNotification } from 'element-plus'

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 获取本地token,判断是否存在
    const access_token = localStorage.getItem('access_token')
    if(!access_token || access_token === ''){
        // 不存在token,则跳到登录页面
        if (to.path === '/login/' || to.path === '/register/') return next()
        ElNotification({title:'温馨提示', message:'没有获取的登录信息,即将跳转到登录页...', type: "error"})
        return next({name: 'login', query:{redirect: to.fullPath}})
    }else if(to.path === '/login/'){
        return next('/')
    }else{
        next()
    }

})
export default router